import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import SignUpForm from "../../../components/forms/signUpForm"
import { navigate } from "gatsby-link"
import PartnerCode from "../../../components/forms/partnerCode"
import PartnerSignUpForm from "../../../components/forms/partnerSignUpForm"

const AccountRegisterPage = () => {
    const [form, setForm] = React.useState("register")
    const [nameHidden, setNameHidden] = React.useState({})

    const goHome = () => {
        navigate("/")
    }

    React.useEffect(() => {
        setForm("register")
    }, [])

    return (
        <div>
            <div className="block md:hidden">
                <div className="flex relative" style={{ height: 240, width: "100%" }}>
                    <StaticImage src="../../../images/account_header.jpg" style={{
                        width: "100%", height: "100%", objectFit: "cover",
                        borderBottomLeftRadius: 170, borderBottomRightRadius: 170
                    }} alt="login banner"></StaticImage>
                    <div className="absolute flex p-5 justify-center items-center text-center w-full h-full">
                        <div className="flex flex-col p-2 justify-between items-center h-full">
                            <StaticImage style={{ width: 60, objectFit: "contain" }} objectFit="contain" src="../../../images/logo_white.png" alt="Shift Logo" ></StaticImage>
                            <h1 className="text-white">Bienvenido</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-8">
                {form === "register" ? <SignUpForm onSignUpSuccess={goHome} onPartnerCodeRequest={() => setForm("partnercode")} ></SignUpForm> : null}
                {form === "partnercode" ? (
                    <PartnerCode
                        setNameHidden={setNameHidden}
                        onSignUpPartnerForm={() => {
                            setForm("partnerSignup")
                        }}
                        onSignInRequest={() => navigate("/pwa/account/login")}
                    />
                ) : null}
                {form === "partnerSignup" ? (
                    <PartnerSignUpForm
                        onSignUpSuccess={goHome}
                        nameHidden={nameHidden}
                        onPartnerCodeRequest={() => {
                            setForm("partnercode")
                        }}
                        onSignInRequest={() => navigate("/pwa/account/login")}
                    />
                ) : null}
            </div>
        </div>

    )
}

export default AccountRegisterPage